import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono!" />
    <h1>404: Nie ma takiej strony!</h1>
    <p>Szukana przez Ciebie strona nie istnieje.</p>
  </Layout>
)

export default NotFoundPage
